<template>
  <div v-if="workInProgress">
    <h1>WORK IN PROGRESS</h1>
  </div>
  <div v-else>
    <div>
      <CModal
        :title="chargebackModal.header"
        :color="chargebackModal.color"
        size="xl"
        centered
        :show.sync="chargebackModal.show"
      >
        <span v-html="chargebackModal.content"> </span>
      </CModal>
    </div>
    <CCard>
      <CCardHeader>
        <h2>CHARGE BACK</h2>
        <div class="card-header-actions">
          <CButton color="light" @click="loadChargeBack(true)"
            ><small class="text-muted">Refresh</small>
          </CButton>
        </div>
        <p>
          Please note that the billing you see on your AWS accounts is
          innacurate because it does not take into consideration the costs of
          Enterprise support, Savings Plan and Enterprise Discount Program.
        </p>
        <h3>NEWS:</h3>
        <div>
          <CButton
            @click="collapse = !collapse"
            color="primary "
            class="mb-2"
            variant="ghost"
          >
            - SAVING PLANS
          </CButton>
          <CCollapse :show="collapse">
            <CCard body-wrapper>
              <p class="card-text">
                In 2020, EC2 Compute and Fargate costs were calculated by
                applying a flat 40% discount on the corresponding on-demand
                cost. This approach was adopted for its simplicity when Savings
                Plan were implemented in early 2020.<br />
                In practice, AWS does not apply the same discount to all compute
                families covered by Compute Savings Plan. Discount varies
                depending on different criteria like OS, licensed software, CPU,
                region etc ...<br />
                To improve the fairness of this chargeback among the various
                entities, we are now taking into consideration these criteria.
                From 2021, Corporate will pass-through the charges based on the
                real compute consumption, either directly assigned on the
                account or as part of the Savings Plan commitment.<br />
                Up to December 2020:<br />
                <span style="font-family: 'Courier New'; font-weight: bold"
                  >"EC2 to be paid" = "EC2 WOSP" * 0,60</span
                ><br />
                From January 2021:<br />
                <span style="font-family: 'Courier New'; font-weight: bold"
                  >"EC2 to be paid" = "EC2 not covered by Savings Plan" + "EC2
                  Savings Plan Commitment"</span
                ><br />
                When an instance (incl. OS licence, DB licence,…) has a low
                discount, it is possible that it will not be covered by Savings
                Plan, this leads to the “EC2 not covered by Savings Plan”
                component.<br />
                With the increased usage of Lambda within ENGIE, Corporate has
                also added Lambda to Savings Plan chargeback in 2021. In 2020,
                lambda workload covered by Savings plan were not reinvoiced to
                entities.
              </p>
            </CCard>
          </CCollapse>
        </div>
        <!-- <p>last refresh: {{ lastFetch }}</p> -->

        <div class="card-header-actions">
          <CButton block color="light" @click="csvExport(csvData)"
            ><small class="text-muted">download csv</small>
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="results"
          :fields="fields"
          table-filter
          column-filter
          items-per-page-select
          :items-per-page="5"
          pagination
          sorter
          striped
          :loading="isLoading"
          :sorterValue="{ column: 'ScopeId', asc: true }"
          v-on:filtered-items-change="filtered"
        >
          <template #ComputeWOSP="{ item }">
            <td class="less-visible-cols">{{ item.ComputeWOSP }}</td>
          </template>
          <template #ComputeOriginal="{ item }">
            <td class="less-visible-cols">{{ item.ComputeOriginal }}</td>
          </template>
          <template #ComputeCommitmentValue="{ item }">
            <td class="less-visible-cols">{{ item.ComputeCommitmentValue }}</td>
          </template>
          <template #LambdaWOSP="{ item }">
            <td class="less-visible-cols">{{ item.LambdaWOSP }}</td>
          </template>
          <template #LambdaOriginal="{ item }">
            <td class="less-visible-cols">{{ item.LambdaOriginal }}</td>
          </template>
          <template #LambdaCommitmentValue="{ item }">
            <td class="less-visible-cols">{{ item.LambdaCommitmentValue }}</td>
          </template>
          <template #FargateWOSP="{ item }">
            <td class="less-visible-cols">{{ item.FargateWOSP }}</td>
          </template>
          <template #FargateOriginal="{ item }">
            <td class="less-visible-cols">{{ item.FargateOriginal }}</td>
          </template>
          <template #FargateCommitmentValue="{ item }">
            <td class="less-visible-cols">{{ item.FargateCommitmentValue }}</td>
          </template>
        </CDataTable>
        <p>
          <b>*Platform Usage:</b> the total cost for all the AWS services
          except: EC2, Fargate, Lambda.
        </p>
        <p><b>*WOSP:</b> Without saving plan.</p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chargebackModal: "",
      workInProgress: false,
      collapse: false,
      innerCollapse: false,
      isLoading: false,
      results: [],
      filtered_data: [],
      fields: [
        { key: "GBUs", label: "GBUs" },
        { key: "ScopeId", label: "ScopeId" },
        { key: "Scope", label: "Scope" },
        { key: "SubScopeId", label: "SubScopeId" },
        { key: "SubScope", label: "SubScope" },
        { key: "BU", label: "BU" },
        { key: "BE", label: "BE" },
        { key: "OrgId", label: "OrgId" },
        { key: "AccountAlias", label: "Account Alias" },
        { key: "BillingCenter", label: "Billing Center" },
        { key: "Id", label: "Id" },
        { key: "Year", label: "Year" },
        { key: "Month", label: "Month" },
        { key: "PlatformUsage", label: "Platform Usage*" },
        { key: "EDP", label: "EDP" },
        { key: "Credits", label: "Credits" },
        { key: "Refunds", label: "Refunds" },
        { key: "Marketplace", label: "Marketplace" },
        { key: "RI1Year", label: "RI1Year" },
        { key: "RI3Years", label: "RI3Years" },
        { key: "SupportCost", label: "Support Cost" },
        { key: "EDP_RI", label: "EDP_RI" },
        {
          key: "ComputeWOSP",
          label: "EC2 WOSP",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "ComputeCommitmentValue",
          label: "EC2 SP Commitment",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "ComputeOriginal",
          label: "EC2 not covered by SP",
          _style: "color: gray;opacity: 40%;",
        },
        { key: "ComputeWithSP_visible", label: "EC2 to be paid" },
        {
          key: "LambdaWOSP",
          label: "Lambda WOSP",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "LambdaCommitmentValue",
          label: "Lambda SP Commitment",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "LambdaOriginal",
          label: "Lambda not covered by SP",
          _style: "color: gray;opacity: 40%;",
        },
        { key: "LambdaWithSP_visible", label: "Lambda to be paid" },
        {
          key: "FargateWOSP",
          label: "Fargate WOSP",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "FargateCommitmentValue",
          label: "Fargate SP Commitment",
          _style: "color: gray;opacity: 40%;",
        },
        {
          key: "FargateOriginal",
          label: "Fargate not covered by SP",
          _style: "color: gray;opacity: 40%;",
        },
        { key: "FargateWithSP_visible", label: "Fargate to be paid" },
        { key: "OneTime", label: "Corp. Adjustments" },
        { key: "Total_visible", label: "Total" },
      ],
    };
  },

  computed: {
    csvData() {
      return this.filtered_data.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    async loadChargebackModal(accessToken) {
      try {
        this.Loading = true;
        // var accessToken = await this.$auth.getAccessToken();
        await this.$store.dispatch(
          "portalParametersModule/loadMessagesParameters",
          {
            accessToken: accessToken,
          }
        );
        this.Loading = false;
        this.chargebackModal =
          this.$store.getters["portalParametersModule/chargebackModal"];
      } catch (e) {
        console.log(e);
      }
    },
    async loadChargeBack(refresh = false) {
      this.isLoading = true;
      var accessToken = await this.$auth.getAccessToken();
      await this.$store.dispatch("chargeBackModule/loadChargeBack", {
        refresh: refresh,
        accessToken: accessToken,
      });
      this.isLoading = false;
      this.parse_data();
    },
    parse_data() {
      const response = this.$store.getters["chargeBackModule/chargeBack"];
      const data = response.dataframes.data;
      const headers = [];
      const result = [];
      for (const key in response.dataframes.columns) {
        const row = response.dataframes.columns[key];
        headers.push(row);
      }
      for (const datarow in data) {
        const obj = {};
        for (const col in data[datarow]) {
          obj[headers[col]] = data[datarow][col];
        }
        result.push(obj);
      }
      this.results = result;
    },
    filtered(event) {
      // console.log(event);
      this.filtered_data = event;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();
    if (
      this.$store.getters[
        "portalParametersModule/chargeBackAccountWorkInProgress"
      ] === undefined
    ) {
      await this.$store.dispatch("portalParametersModule/loadWipParameters", {
        refresh: true,
        accessToken: accessToken,
      });
    }
    this.workInProgress =
      this.$store.getters[
        "portalParametersModule/chargeBackAccountWorkInProgress"
      ];
    await this.loadChargebackModal(accessToken);
    if (!this.workInProgress) {
      this.loadChargeBack();
    }
  },
};
</script>
